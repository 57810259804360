import React from 'react';
import Rsponsors from '../assets/img/Rsponsors.png';

export default class Regularsponsors extends React.Component {
    constructor() {
        super();
        this.state = { color: "red" };
    }
    render() {
        return (
            <>
<div class="container-fluid p-0 mb-5">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
          <img className="img-fluid w-100 w-sm-100 w-md-100" src={Rsponsors} alt="Group" />
      </div>
    </div>
            <div className='container'>
                <div className='row mt-2'>
                <h2 style={{color:"#731414"}}>Regular Sponsors</h2>  
                </div>
            </div>
            </>
        );
    }
}