// // import React, { Component } from 'react';
// // class Gallery extends Component {
// //   constructor(props) {
// //     super(props);
// //     this.state = {
// //       selectedYear: null,
// //       selectedImage: null,
// //       isLightboxOpen: false,
// //       images: props.images || [
// //         { id: 1, src: "https://trust.sharavisual.com/assets/gallery/gallery1.jpg", title: "Image 1", year: 2021, name: "Kalotsav" },
// //         { id: 2, src: "https://trust.sharavisual.com/assets/gallery/gallery2.jpg", title: "Image 2", year: 2020, name: "Kalotsav" },
// //         { id: 3, src: "https://trust.sharavisual.com/assets/gallery/gallery3.jpg", title: "Image 3", year: 2021, name: "Kalotsav" },
// //         { id: 4, src: "https://trust.sharavisual.com/assets/gallery/gallery4.jpg", title: "Image 4", year: 2019, name: "Kalotsav" },
// //         { id: 5, src: "https://trust.sharavisual.com/assets/gallery/gallery5.jpg", title: "Image 5", year: 2020, name: "Kalotsav" },
// //       ],
// //     };
// //   }

// //   // Toggle lightbox visibility
// //   openLightbox = (image) => {
// //     this.setState({ selectedImage: image, isLightboxOpen: true });
// //   };

// //   closeLightbox = () => {
// //     this.setState({ selectedImage: null, isLightboxOpen: false });
// //   };

// //   // Filter images by name and year
// //   filterByYear = (year) => {
// //     this.setState({ selectedYear: year });
// //   };

// //   render() {
// //     const { selectedYear, selectedImage, isLightboxOpen, images } = this.state;

// //     // Get unique combinations of name and year from images
// //     const years = [...new Set(images.map((img) => `${img.name}-${img.year}`))];

// //     // Filter images based on selected name and year
// //     const filteredImages = selectedYear
// //       ? images.filter((img) => `${img.name}-${img.year}` === selectedYear)
// //       : images;

// //     return (
// //       <div className='container'>
// //         <div className="gallery-container">
// //           {/* Sidebar with year filter */}
        

// //           {/* Gallery grid */}
// //           <div className="gallery-grid">
// //   {filteredImages.map((image) => (
// //     <div key={image.id} className="gallery-item" onClick={() => this.openLightbox(image)}>
// //       <img src={image.src} alt={image.title} className="img-fluid" />
// //     </div>
// //   ))}
// // </div>



// //           {/* Lightbox */}
// //           {isLightboxOpen && selectedImage && (
// //             <div className="lightbox" onClick={this.closeLightbox}>
// //               <div className="lightbox-content">
// //                 <span className="close" onClick={this.closeLightbox}>&times;</span>
// //                 <img src={selectedImage.src} alt={selectedImage.title} />
// //                 <p>{selectedImage.title}</p>
// //               </div>
// //             </div>
// //           )}
// //             <div className="sidebar bg-white ">
// //             <h5>Abhinayam with Vesham</h5>
// //             <ul>
// //               <li onClick={() => this.filterByYear(null)}>All Years</li>
// //               {years.map((year) => (
// //                 <li key={year} onClick={() => this.filterByYear(year)}>
// //                   {year}
// //                 </li>
// //               ))}
// //             </ul>
// //           </div>
// //         </div>
// //       </div>
// //     );
// //   }
// // }

// // export default Gallery;


// import React, { Component } from 'react';
// import axios from 'axios';

// class Gallery extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       filteredImages:[],
//       rightMenu:[],
//       selectedYear: null,
//       selectedName: null,
//       selectedImage: null,
//       isLightboxOpen: false,
//       images: [], // Fetch dynamically
//     };
//   }

//   componentDidMount() {
//     // Fetch sponsor gallery data
//     this.fetchImages();
//   }

//   fetchImages = async () => {
//     try {
//       const response = await axios.get('https://trust.sharavisual.com/api/getsponsor.php');
//        // Extract unique combinations of names and yearsht      
//        debugger;
//        if(response.data.length>0){
//         let menu=[];
//          menu = [...new Set(response.data.map((img) => `${img.name}-${img.year}`))];
//         this.setState({ 
//           images: response.data,
//           rightMenu:menu,
//           filteredImages:response.data });
//        }
     

//     } catch (error) {
//       console.error('Error fetching images:', error);
//     }
//   };

//   openLightbox = (image) => {
//     this.setState({ selectedImage: image, isLightboxOpen: true });
//   };

//   closeLightbox = () => {
//     this.setState({ selectedImage: null, isLightboxOpen: false });
//   };

//   filterByYearAndName = (year, name) => {
//     let filteredImages=[];
//     debugger;
//  // Filter images based on selected name and year
//      // Filter images based on selected year
//      filteredImages = year
//      ? this.state.images.filter((img) => String(img.year) === String(year))
//      : this.state.images;

//  // Debugging output
//  console.log("Filtered Images: ", filteredImages);
//     this.setState({ selectedYear: year,
//                      selectedName: name,
//                      filteredImages:filteredImages });

//   };

//   render() {
//     const { selectedYear, selectedName, selectedImage, isLightboxOpen, images } = this.state;
// debugger;
  

//     debugger
//     return (
     
//       <div className='container'>
//         {this.state.filteredImages.length >0 ? <div className="gallery-container">
//           {/* Gallery grid */}
//           <div className="gallery-grid">
//             {this.state.filteredImages.length >0 &&  this.state.filteredImages.map((image) => {
//               debugger;
//               return(
//               <div key={image.id} className="gallery-item" onClick={() => this.openLightbox(image)}>
//                 <img src={"https://trust.sharavisual.com/" +image.image_path} alt={image.title} className="galleyimg-fluid" />
//               </div>
//             )})}
//           </div>

//           {/* Lightbox */}
//           {isLightboxOpen && selectedImage && (
//             <div className="lightbox" onClick={this.closeLightbox}>
//               <div className="lightbox-content">
//                 <span className="close" onClick={this.closeLightbox}>&times;</span>
//                 <img src={"https://trust.sharavisual.com/" + selectedImage.image_path} alt={selectedImage.title} />
//                 <p>{selectedImage.name}</p>
//               </div>
//             </div>
//           )}

//           {/* Sidebar */}
//           <div className="sidebar bg-white">
//             <h5>Abhinayam with Vesham</h5>
//             <ul>
//               <li className={this.state.selectedYear == null ? 'galleryLinkActive' :'galleryLinkInactive'} onClick={() => this.filterByYearAndName(null, null)}>All Years</li>
//               {this.state.rightMenu.length>0 && this.state.rightMenu.map((filter) => {
//                 debugger
//                 const [name, year] = filter.split('-');
//                 return (
//                   <li className={this.state.selectedYear == year ? 'galleryLinkActive' :'galleryLinkInactive'} key={filter} onClick={() => this.filterByYearAndName(Number(year), name)}>
//                     {name} - {year}
//                   </li>
//                 );
//               })}
//             </ul>
//           </div>
//         </div> : <p className={'text-center'}>--No Gallery Images --</p> }
//         </div>
//     );
//   }
// }

// export default Gallery;


import React, { Component } from 'react';
import axios from 'axios'; // Import axios for API calls
import { Gallery } from 'react-grid-gallery';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class Gallerys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      filteredImages: [],
      rightMenu: [],
      selectedYear: null,
      selectedName: null,
      index: -1, // Tracks the index of the currently selected image
    };
  }

  componentDidMount() {
    // Fetch sponsor gallery data
    this.fetchImages();
  }

  fetchImages = async () => {
    try {
      const response = await axios.get('https://trust.sharavisual.com/api/getsponsor.php');
      if (response.data.length > 0) {
        const menu = [...new Set(response.data.map((img) => `${img.name}-${img.year}`))];
        const formattedImages = response.data.map((image) => ({
          src: `https://trust.sharavisual.com/${image.image_path}`,
          width: 4, // Default width (adjustable)
          height: 3, // Default height (adjustable)
          alt: image.name, // Use `name` for `alt`
          year: image.year, // Use `year` as a separate field
          id: image.id,
        }));

        this.setState({
          images: formattedImages,
          filteredImages: formattedImages,
          rightMenu: menu,
        });
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  filterByYearAndName = (year, name) => {
    const { images } = this.state;

    // Filter images by year and name if both are provided
    const filteredImages = images.filter((img) => {
      const matchesYear = year ? String(img.year) === String(year) : true;
      const matchesName = name ? img.alt === name : true;
      return matchesYear && matchesName;
    });

    this.setState({
      selectedYear: year,
      selectedName: name,
      filteredImages,
    });
  };

  handleClick = (index) => {
    this.setState({ index });
  };

  handleClose = () => {
    this.setState({ index: -1 });
  };

  handleMovePrev = () => {
    const { index, filteredImages } = this.state;
    const prevIndex = (index + filteredImages.length - 1) % filteredImages.length;
    this.setState({ index: prevIndex });
  };

  handleMoveNext = () => {
    const { index, filteredImages } = this.state;
    const nextIndex = (index + 1) % filteredImages.length;
    this.setState({ index: nextIndex });
  };

  render() {
    const { filteredImages, rightMenu, selectedYear, index } = this.state;
    const currentImage = filteredImages[index];
    const nextIndex = (index + 1) % filteredImages.length;
    const nextImage = filteredImages[nextIndex] || currentImage;
    const prevIndex = (index + filteredImages.length - 1) % filteredImages.length;
    const prevImage = filteredImages[prevIndex] || currentImage;

    return (
      <div className="container mt-5">
        {filteredImages.length > 0 ? (
          <div className="row">
            {/* Gallery Grid */}
            <h2 style={{color: "rgb(115, 20, 20)"}}>Gallery </h2>
            <div className="col-8 col-lg-10">
              <Gallery
                images={filteredImages}
                onClick={this.handleClick}
                enableImageSelection={false}
              />
            </div>

            {/* Lightbox */}
            {currentImage && (
              <Lightbox
                mainSrc={currentImage.src}
                nextSrc={nextImage.src}
                prevSrc={prevImage.src}
                onCloseRequest={this.handleClose}
                onMovePrevRequest={this.handleMovePrev}
                onMoveNextRequest={this.handleMoveNext}
              />
            )}

            {/* Sidebar */}
            <div className="col-4 col-lg-2 sidebar bg-white">
              <h5>Abhinayam with Vesham</h5>
              <ul>
                <li
                  className={selectedYear == null ? 'galleryLinkActive' : 'galleryLinkInactive'}
                  onClick={() => this.filterByYearAndName(null, null)}
                >
                  All Years
                </li>
                {rightMenu.map((filter) => {
                  const [name, year] = filter.split('-');
                  return (
                    <li
                      className={selectedYear == year ? 'galleryLinkActive' : 'galleryLinkInactive'}
                      key={filter}
                      onClick={() => this.filterByYearAndName(Number(year), name)}
                    >
                      {name} - {year}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : (
          <p className="text-center">--No Gallery Images--</p>
        )}
      </div>
    );
  }
}

export default Gallerys;
