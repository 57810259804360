import React, { Component } from 'react';
import QR from '../assets/img/image 1.png';
import DonationAccountInfo from "../component/donationAccount";
import DonationForm from './donationForm';
import InvolvedFrom from './involvedForm';
class Involved extends Component {
    constructor() {
        super();
        this.state = {
            getInvolvedInfo:{},
            getInvlovedContent:[],
                      leftSectionAccount:[],
                      isShowAccountInfo:false
                     };
      }
    getDonationAccountInfo=()=>{
        fetch('https://trust.sharavisual.com/data/athirudram.json')
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          this.setState({leftSectionAccount:data.leftSectionAccount,
                         })
          console.log('Fetched data:', data);
          // You can now use the data in your component state
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        }); 
    }
    getInvlovedContent=()=>{
        fetch('https://trust.sharavisual.com/data/getInvolved.json')
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          this.setState({
            getInvolvedInfo:data,
            getInvlovedContent:data.getInvolvedContent,
                         })
          console.log('Fetched data:', data);
          // You can now use the data in your component state
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        }); 
    }
    componentDidMount() {
        
        this.getDonationAccountInfo();
        this.getInvlovedContent();
      } 
    showAccountInfo=(selectedParam)=>{
      debugger;
         this.setState({isShowAccountInfo:selectedParam})
      }  
    render() {
        
        return (

            <div className="container mt-5">
                <div className="row">
                    <h2 style={{ color: "#731414" }}>{this.state.getInvolvedInfo.heading!=undefined && this.state.getInvolvedInfo.heading}</h2>
                    {this.state.getInvolvedInfo.subHeading!=undefined && this.state.getInvolvedInfo.subHeading !="" && <h4>{this.state.getInvolvedInfo!=undefined && this.state.getInvolvedInfo.subHeading}</h4>}

                    <div className="col-lg-12 mt-3 wow fadeInUp" data-wow-delay="0.1s">
                            {this.state.getInvlovedContent.length>0 && this.state.getInvlovedContent.map((item,index)=>{
                                return(
                                    <>
                                    {item.heading != "" && <h5>{item.heading}</h5>}
                                    {item.subTitle != "" && <h6>{item.subTitle}</h6>}
                                    <div dangerouslySetInnerHTML={{__html: item.description}}></div>
                                    </>
                                )
                            })}
                            
                        
                    </div>
                </div>
                <div className='row mb-5 d-flex justify-content-center'>
                  <div className='col-12 col-lg-3 mt-lg-5'>
            {this.state.isShowAccountInfo == true && this.state.leftSectionAccount.length > 0 && this.state.leftSectionAccount.map((item, index) => {
                return (
            <>
              <p className='mb-0 text-justify-normal'>
                <b>Account Name:</b> {item.accontName},<br />
                <b>Bank:</b> {item.bank}<br />
                <b>Branch:</b> {item.branch}<br />
                <b>Account Type: {item.accontType}</b><br />
                <b>Account Number: <span className='fw-1 fs-4' style={{ wordBreak: "break-word" }}>{item.accountNumber}</span></b>
              </p>
              <p className='fw-1 fs-5 mb-0 text-justify-normal'>IFSC CODE: {item.ifscCode}</p>
              <p className='fw-1 fs-5 text-justify-normal'>UPI ID: {item.upiId}</p>
              <div className="text-sm-start text-center">
                <h5 className="mb-3 text-center">Donations</h5>
                <div className="d-flex justify-content-center justify-content-sm-center ">
                  <img
                    src={item.qurCode}
                    className="flex-shrink-0 me-3 mb-3 img-fluid"
                    style={{ width: "180px" }}
                    alt="QR Code"
                  />
                </div>
              </div>
            </>)
        })}</div>
                    <div class={this.state.isShowAccountInfo == true ? "col-lg-9 grid-margin stretch-card mt-3 wow fadeInUp":"col-lg-10 grid-margin stretch-card mt-3 wow fadeInUp"} data-wow-delay="0.1s">
                        <div class="card">
                            <div class="card-body">
                                <InvolvedFrom {...this.props} accountInfo={this.showAccountInfo}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className="col-lg-12 mt-3 wow fadeInUp" data-wow-delay="0.1s">
                        <p><strong>{this.state.getInvolvedInfo.note!=undefined && this.state.getInvolvedInfo.note}</strong></p>
                    </div>
                </div>
                {/* <div className='row mb-5 g-5'>
                    <div className="col-12 col-lg-4 mt-3 wow fadeInUp text-center" data-wow-delay="0.1s">
                    <DonationAccountInfo {...this.state}/>                        
                    </div>
                    <div class="col-12 col-md-8 col-lg-8 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="card p-4">      
                        <DonationForm/>
                    </div>
                </div>
                </div> */}
            </div>



        );
    }
}

export default Involved;
